<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(type)="data">
          {{ $t(`general.seo_template_types.${data.item.type}`) }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <span
              v-if="$ability.can('update', 'seo') && ['page', 'post', 'category', 'product', 'promotion'].includes(data.item.type)"
              class="btn btn-warning btn-sm"
              @click="sync(data.item.id)"
            >
              <feather-icon
                icon="SkipForwardIcon"
                size="16"
                class="align-middle"
              />
            </span>
            <span
              v-if="$ability.can('update', 'seo') && ['page', 'post', 'category', 'product', 'promotion'].includes(data.item.type)"
              class="btn btn-danger btn-sm ml-1"
              @click="generate(data.item.id)"
            >
              <feather-icon
                icon="RefreshCwIcon"
                size="16"
                class="align-middle"
              />
            </span>
            <router-link
              v-if="$ability.can('update', 'seo')"
              :to="{ name: 'seo_templates-update', params: { id: data.item.id } }"
              class="btn btn-primary btn-sm ml-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="align-middle"
              />
            </router-link>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useList from './useList'
import storeModule from './storeModule'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      tableColumns: [
        { key: 'type', label: this.$t('admin.table.fields.type'), sortable: false },
        { key: 'title', label: this.$t('admin.table.fields.title'), sortable: false },
        { key: 'h1', label: this.$t('admin.table.fields.h1'), sortable: false },
        { key: 'description', label: this.$t('admin.table.fields.description'), sortable: false },
        { key: 'actions', label: this.$t('admin.table.fields.actions') },
      ],
    }
  },
  methods: {
    sync(id) {
      this.confirm(() => {
        this.$http.post(`/api/admin/seo_templates/${id}/sync`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          })
      }, {
        text: 'Дана операція згенерує нові метатеги (наявні залишаться без змін) всіх записів даної сутності згідно з шаблоном. Дана операція безповоротна!',
      })
    },
    generate(id) {
      this.confirm(() => {
        this.$http.post(`/api/admin/seo_templates/${id}/generate`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: 'Дана операція ПЕРЕГЕНЕРУЄ метатеги (наявні теж) всіх записів даної сутності згідно з шаблоном. Дана операція безповоротна!',
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'seo_templates'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      typeFilter,

    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      typeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
